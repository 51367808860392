import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import SupportPage from "../../components/layout/SupportPage"
import PageHeader from "../../components/layout/PageHeader"
import GoogleImage from "../../components/GoogleImage"

const List = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  li {
    background-color:white;
    padding: 5px;
    margin-bottom: 5px;

    a {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 12px;
      height: 75px;
      width: 60px;
    }
  }
`

const DocumentsPage = () => (
  <SupportPage page="resources" title="Product Information and Sign Resources">
    <PageHeader title="Sign Resources" />

    <List>
      <li>
        <Link to="/documents/permit-guide/">
          <GoogleImage src="Documents/permit-guide.jpg" required lazy alt="Sign Permit Guide" width={60} height={75} />
          Sign Permit Guide
        </Link>
      </li>
      <li>
          <Link to="/documents/buying-guide/">
            <GoogleImage src="Documents/channel-letters-buying-guide.jpg" required lazy alt="Channel Letters - Buying Guide" width={60} height={75} />
            Channel Letters Buying Guide
          </Link>
      </li>
      <li>
          <Link to="/warranty/">
            <GoogleImage src="Documents/warranty.jpg" required lazy alt="SignMonkey Warranty" width={60} height={75} />         
            SignMonkey Warranty
          </Link>
      </li>
      <li>
          <Link to="/documents/fasteners/">
            <GoogleImage src="Documents/sign-mounting-fastener-suggestions.jpg" required lazy alt="Channel Letters Mounting Fastener Suggestions" width={60} height={75} />      
            Channel Letters Mounting Fastener Suggestions
          </Link>
      </li>
      <li>
          <Link to="/documents/solar/">
            <GoogleImage src="Documents/solar-sign-lighting-instructions.jpg" required lazy alt="Solar Sign Lighting Instructions" width={60} height={75} />       
            Solar Sign Lighting Instructions
          </Link>
      </li>
      <li>
        <a href="https://storage.googleapis.com/signmonkey-148101.appspot.com/files/resources/rw/signmonkey-roof-mount-bracket-specifications-for-raceway-channel-letters.dAd62q9b7u.pdf">
          <GoogleImage src="Documents/roof-mount-bracket-specifications-for-raceway-channel-letters.jpg" required lazy alt="SignMonkey Roof Mount Bracket Specifications for Raceway Channel Letters" width={60} height={75} />        
          SignMonkey Roof Mount Bracket Specifications for Raceway Channel Letters
        </a>
      </li>
      <li>
        <Link to="/products/face-lit-channel-letters-and-logos/standard/">
          <GoogleImage src="Documents/standard_letters_and_shapes.jpg" required lazy alt="SignMonkey Standard Letters and Shapes" width={60} height={75} />        
          Standard Letters and Shapes 
        </Link>
      </li>
      <li>
        <Link to="/products/face-lit-channel-letters-and-logos/nonstandard/">
          <GoogleImage src="Documents/non_standard_letters_and_shapes.jpg" required lazy alt="SignMonkey Non-Standard Letters and Shapes" width={60} height={75} />        
          Non-Standard Letters and Shapes 
        </Link>
      </li>
    </List>
  </SupportPage>
)

export default DocumentsPage
